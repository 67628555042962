import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "@layout/index";
import GraphicText from "@/layout/GraphicText";

import Header from "@components/head";
import TopCardWrap from "@/content/use-cases/TopCardWrap";

import {
  Text,
  List,
  Button,
  ListItem,
  Box,
  Link as ChakraLink,
  VStack,
  Flex,
} from "@chakra-ui/react";
import BottomLayout from "@/layout/BottomLayout";
import { HOST_URL } from "@/utils/constant";

const { useState } = React;

export const Head = () => {
  return (
    <Header
      ogUrl={`${HOST_URL}/use-cases/product-managers`}
      title="Run Better Sprint and Scrum Meetings | Airgram for Product Managers"
      description="Airgram helps level up sprint and scrum meetings through real-time transcription, timestamped notes and HD video recordings. Zoom, Google Meet and Microsoft Teams meetings supported."
    />
  );
};

const IndexPage = () => {
  return (
    <Layout
      bottomLayout={
        <BottomLayout
          signGtmId="web_sign_up_cta_btn_use_case"
          description="Effectively communicate and collaborate with your scrum teams"
          buttonText="Try Out Now"
          buttonLink="https://app.airgram.io/signup"
        />
      }
    >
      <TopCardWrap>
        <Text w="80%" textAlign="center" fontSize="32px" as="h1">
          Energize product managers to host efficient meetings
        </Text>
        <Text textAlign="center" fontSize={18}>
          Schedule, record, transcribe, document, and share - recharge your
          scrum team with our one-stop service
        </Text>

        <ChakraLink
          _hover={{
            textDecor: "none",
          }}
          lineHeight="52px"
          target="_blank"
          mt="24px"
          href="https://app.airgram.io/signup"
        >
          <Button
            data-gtm="web_sign_up_cta_btn_use_case"
            size="lg"
            colorScheme="brand"
          >
            Get Started Free
          </Button>
        </ChakraLink>
      </TopCardWrap>

      <VStack my="80px" gap={{ base: "40px", md: "80px", lg: "120px" }}>
        {/* 左边图片，右边文字的布局 */}
        <GraphicText
          placement="right"
          title="Lead focused meeting with live transcription"
          slot={
            <StaticImage
              loading="lazy"
              width={540}
              style={{ borderRadius: "12px" }}
              src="https://a.storyblok.com/f/167495/1223x756/c995690a20/usecaseproductmanagers1.png"
              alt="Use Case Team"
            />
          }
        >
          <Flex
            flexDir="column"
            gap="12px"
            flex={1}
            bgColor="white"
            borderRadius="12px"
            color="#220247"
          >
            <Text fontSize={18}>
              Create agenda from Google Calendar. Focus on articulating the
              product vision, roadmaps, and requirements with live transcription
              and video recordings. Ensure everything is crystal clear before
              the product development.
            </Text>
            <Text fontSize={18}>
              Schedule AI meeting assistant to auto join your Zoom meetings.
              Skip the manual setup to boost the meeting efficiency.
            </Text>

            <List pl={0}>
              <ListItem>
                🔹 Automatic live transcription: Zoom (auto-join), Google Meet,
                Microsoft Teams
              </ListItem>
              <ListItem>🔹 Speaker detection</ListItem>
              <ListItem>🔹 Downloadable video recording</ListItem>
            </List>
          </Flex>
        </GraphicText>

        {/* 左边文字，右边图片的布局 */}
        <GraphicText
          title="Assign action items to move the scrum team forward"
          slot={
            <StaticImage
              loading="lazy"
              width={540}
              style={{ borderRadius: "12px" }}
              src="https://a.storyblok.com/f/167495/1260x769/333026a59d/usecaseproductmanagers2.png"
              alt="Google Meet Transcript"
            />
          }
        >
          <Flex
            flexDir="column"
            gap="12px"
            flex={1}
            bgColor="white"
            borderRadius="12px"
            color="#220247"
          >
            <Text fontSize={18}>
              Assign action items before the sprint planning ends. Prioritize
              what your team needs to handle and set due dates. Ensure everyone
              is on track and on time.
            </Text>

            <List pl={0}>
              <ListItem>🔹 Centralized action items for follow-up</ListItem>
            </List>
          </Flex>
        </GraphicText>

        <GraphicText
          placement="right"
          title="Circulate meeting notes to keep everyone aligned"
          slot={
            <StaticImage
              loading="lazy"
              width={540}
              style={{ borderRadius: "12px" }}
              src="https://a.storyblok.com/f/167495/1192x738/19ff4110c8/usecaseproductmanagers3.png"
              alt="Google Meet Collaborative Notes"
            />
          }
        >
          <Flex
            flexDir="column"
            gap="12px"
            flex={1}
            bgColor="white"
            borderRadius="12px"
            color="#220247"
          >
            <Text fontSize={18}>
              Share with the development team via Slack, Notion, Google Docs, or
              Microsoft Word. Team members can instantly get the key issues and
              next steps even if someone isn’t available. Timestamped notes are
              associated with the relevant recording and transcript text.
            </Text>
            <List pl={0}>
              <ListItem>
                🔹 Slack, Notion, Google Docs, Microsoft Word integrations
              </ListItem>
              <ListItem>
                🔹 Timestamped notes, synced text highlighting
              </ListItem>
              <ListItem>🔹 Search and replace</ListItem>
            </List>
          </Flex>
        </GraphicText>

        <GraphicText
          title="Retrieve key points with less effort"
          slot={
            <StaticImage
              loading="lazy"
              width={540}
              style={{ borderRadius: "12px" }}
              src="https://a.storyblok.com/f/167495/1131x733/2ddce7d17a/usecaseproductmanagers4.png"
              alt="Google Meet Collaborative Notes"
            />
          }
        >
          <Flex
            flexDir="column"
            gap="12px"
            flex={1}
            bgColor="white"
            borderRadius="12px"
            color="#220247"
          >
            <Text as="h4" fontSize="24px" fontWeight="600"></Text>
            <Text fontSize={18}>
              Quickly skim for essential information with AI entity extraction.
              Select a specific speaker to playback recording. What used to take
              you hours now only takes you minutes.
            </Text>
            <Text fontSize={18}>
              Spend more time developing new features to address customer needs.
              Video recording facilitates the developers to review the meeting
              details at any time. So you will no longer repeatedly confirm the
              same things.
            </Text>

            <List pl={0}>
              <ListItem>🔹 Differentiate between different speakers</ListItem>
              <ListItem>
                🔹 AI entity extraction - people, dates, numbers, places,
                brands, etc.
              </ListItem>
              <ListItem>🔹 Multiple playback speeds, skip silence</ListItem>
            </List>
          </Flex>
        </GraphicText>
      </VStack>
    </Layout>
  );
};

export default IndexPage;
